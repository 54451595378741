'use client';

import { assetsService } from '@mpx-sdk/api-client';
import { adminFeaturesAtom, userRolesAtom } from '@mpx-sdk/shared/atoms';
import { type PublicAsset } from '@mpx-sdk/types';
import { StarBorder as StarBorderIcon, Star as StarIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useAtomValue } from 'jotai';
import { type ReactElement, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

interface ToggleFeaturedAssetsProps {
	/** Whether the button should be displayed using default conditions [true, default] or by force [false] */
	conditionalDisplay?: boolean;
	/** The project data to toggle */
	projectData: PublicAsset;
}

/** A component that displays a button to toggle a project as a featured asset or not. */
export default function ToggleFeaturedAssets({
	conditionalDisplay = true,
	projectData,
}: ToggleFeaturedAssetsProps): ReactElement | null {
	const [isFeatured, setIsFeatured] = useState<boolean>(true);
	const adminView = useAtomValue(adminFeaturesAtom);
	const themes = useTheme();
	const currentUserRoles = useAtomValue(userRolesAtom);

	const toggleFeaturedAsset = async () => {
		if (currentUserRoles?.library && adminView) {
			try {
				await assetsService.adminUpdatePublicProject(projectData.id, {
					isStarred: !projectData.isStarred,
				});

				projectData.isStarred = !projectData.isStarred;
				setIsFeatured(!isFeatured);

				toast.success(
					`Project ${projectData.title} is ${projectData.isStarred ? 'now ' : 'no longer'} a featured asset`,
				);
			} catch (error: any) {
				console.error(error);
				toast.error(error.message);
			}
		} else {
			toast.error('Only admins can toggle public assets');
		}
	};

	useEffect(() => {
		setIsFeatured(projectData.isStarred ?? false);
	}, [projectData]);

	return (
		currentUserRoles?.library &&
		(adminView || !conditionalDisplay) && (
			<Tooltip
				arrow
				describeChild
				title={`Toggle ${projectData?.title || 'Untitled project'} as a featured asset`}
			>
				<IconButton aria-label='toggle public asset' className='admin-edit' onClick={toggleFeaturedAsset}>
					{isFeatured ? (
						<StarIcon
							sx={{
								color: `${themes.palette.icons.active} !important`,
							}}
						/>
					) : (
						<StarBorderIcon />
					)}
				</IconButton>
			</Tooltip>
		)
	);
}
