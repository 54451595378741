/* eslint-disable no-nested-ternary */
import { LogoXIcon } from '@mpx-sdk/images';
import { adminFeaturesAtom, userAtom, userRolesAtom } from '@mpx-sdk/shared/atoms';
import { type UserProfile } from '@mpx-sdk/types';
import {
	AddTask,
	AdminPanelSettings,
	BusinessCenter,
	Code,
	Engineering,
	SensorOccupied,
	TaskAlt,
} from '@mui/icons-material';
import { Avatar, type AvatarProps, Badge } from '@mui/material';
import { useAtomValue } from 'jotai';
import { isArray } from 'lodash';
import { type ReactElement, type ReactNode, useEffect, useState } from 'react';

interface VerifiedAvatarProps extends AvatarProps {
	/** The user to display the avatar for */
	user?: UserProfile | null;
	/** Custom styles for the avatar */
	styles?: AvatarProps['sx'];
	/** Whether this avatar faces the public or to the current user only */
	publicFacing?: boolean;
	/** Child elements to wrap */
	children?: ReactNode | string;
}

/** A wrapper component for the MUI Avatar component that displays a verified badge if the user is verified */
export default function VerifiedAvatar({
	children,
	publicFacing,
	styles,
	user,
	...props
}: Readonly<VerifiedAvatarProps>): ReactElement {
	const [badgeBackground, setBadgeBackground] = useState('primary.main');
	const [verifiedIcon, setVerifiedIcon] = useState<ReactElement | null>(null);
	const adminView = useAtomValue(adminFeaturesAtom);
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);

	if (currentUser && (!user || user.id === currentUser?.id)) {
		user = currentUser;
	}

	/** Linear gradient for verified icon for official users */
	const mpxGradient = 'linear-gradient(45deg, #f2c03e 0%, #bd1e00 100%)';

	/** List of verified icons and their colors */
	const verifiedIconsList = {
		admin: { icon: <AdminPanelSettings />, color: mpxGradient },
		dev: { icon: <Code />, color: mpxGradient },
		marketing: { icon: <BusinessCenter />, color: mpxGradient },
		official: { icon: <LogoXIcon />, color: mpxGradient },
		affiliate: { icon: <AddTask />, color: '#FF0000' },
		verified: { icon: <TaskAlt />, color: '#1DA1F2' },
		beta: { icon: <SensorOccupied />, color: '#f2c03e' },
		tester: { icon: <Engineering />, color: '#f2c03e' },
	};

	function getVerifiedIcon() {
		// Check if user has any specific roles
		if (user) {
			/** List of user roles */
			const userRoles: string[] | null = isArray(user?.roles?.roles)
				? user?.roles?.roles
				: isArray(user?.roles)
					? user?.roles
					: null;

			if (!userRoles || user.username === 'devops+delete') {
				return null;
			}

			// Go through verified icons list and find the first key that exists in roles
			const verifiedRole = Object.keys(verifiedIconsList).find((role) => userRoles.includes(role));

			if (verifiedRole) {
				setBadgeBackground(verifiedIconsList[verifiedRole].color);

				if (publicFacing) {
					if (verifiedRole === 'tester' || verifiedRole === 'beta') {
						return null;
					}

					return <TaskAlt />;
				}

				return verifiedIconsList[verifiedRole].icon;
			}
		}

		return null;
	}

	useEffect(() => {
		const icon = getVerifiedIcon();

		if (icon) {
			setVerifiedIcon(icon);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, currentUserRoles]);

	return (
		<Badge
			anchorOrigin={{
				horizontal: 'right',
				vertical: 'bottom',
			}}
			aria-label='verified-badge'
			badgeContent={verifiedIcon ?? ''}
			color='primary'
			data-testid={`verified-badge-public-facing-${publicFacing}`}
			invisible={!verifiedIcon || (!publicFacing && !adminView)}
			overlap='circular'
			sx={{
				// There is an issue that with a icon as the badge content, the badge is not longer circular, so we need to make it circular. border-radius does not fix it
				'& .MuiBadge-badge': {
					background: badgeBackground,
					maxHeight: '20px',
					maxWidth: '20px',
					transition: 'all 0.2s ease-in-out',
					svg: {
						color: 'primary.contrastText',
						fontSize: '18px',
					},
				},
			}}
		>
			{children ?? (
				<Avatar
					alt={`${user?.displayName ?? 'User'}'s avatar`}
					imgProps={{
						loading: 'lazy',
					}}
					src={user?.photoUrl ?? `${user?.firstName} ${user?.lastName}`}
					sx={styles}
					{...props}
				/>
			)}
		</Badge>
	);
}
