'use client';

import { UIHelper } from '@mpx-sdk/helpers/ui';
import { CloseIcon } from '@mpx-sdk/images';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { type ReactElement, useEffect, useState } from 'react';

interface HeaderProps {
	onClose?: () => void;
}

export default function SAVHeader({ onClose }: HeaderProps): ReactElement | null {
	const [showBackButton, setShowBackButton] = useState<boolean>(false);
	const responsiveView = UIHelper.isResponsiveView();

	useEffect(() => {
		// Show the back button if there's more than one entry in the history stack
		if (window.history?.length > 1) {
			setShowBackButton(true);
		}
	}, []);

	// Handle functionality of component
	function handleBackButton() {
		onClose?.();
	}

	return showBackButton ? (
		<Stack
			alignItems='center'
			direction='row'
			justifyContent='flex-end'
			spacing={0.5}
			sx={{
				width: '100%',
				margin: '0.2rem 0',
			}}
		>
			<Tooltip arrow describeChild placement='left' title='Close single asset view'>
				<IconButton
					aria-label='Close single asset view'
					data-testid='CloseGallery'
					id='close-sav-button'
					onClick={() => handleBackButton()}
					sx={{
						backgroundColor: responsiveView ? 'initial' : 'background.dark',
					}}
					tabIndex={0}
				>
					<CloseIcon />
				</IconButton>
			</Tooltip>
		</Stack>
	) : null;
}
