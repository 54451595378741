import { assumeTextureType, findProjectThumbnailUrl } from '@mpx-sdk/helpers/assets';
import { defaultThumbnail } from '@mpx-sdk/images';
import { inAppBrowserAtom, singleAssetViewAtom } from '@mpx-sdk/shared/atoms';
import ThumbnailButtons from '@mpx-sdk/ui/components/single-asset-view/components/ThumbnailButtons';
import { useAtomValue } from 'jotai';
import { isEmpty } from 'lodash';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { type ReactElement, Suspense } from 'react';

const ModelViewer = dynamic(() => import('@mpx-sdk/ui/components/single-asset-view/components/ModelViewer'), {
	ssr: false,
});

interface ThumbnailProps {
	/** Project's GLB/GLTF file if fixed (will find one if not provided) */
	projectGLB?: string;
	/** Project's thumbnail URL if fixed (will find one if not provided) */
	thumbnailURL?: string;
}

/**
 * Generate modal/single project view's project thumbnail
 * @returns {ReactElement} Modal/single project view's project thumbnail
 * @example <caption>Generate modal/single project view's project thumbnail. Works for static images, models and materials/textures</caption>
 * <LibraryModalThumbnail projectData={projectData} />
 * // returns <static images or 3D view of a model and materials />
 * @export
 */
export default function Thumbnail({ projectGLB, thumbnailURL }: ThumbnailProps): ReactElement | null {
	/** JSX of what to display */
	let display: any = null;
	const inApp = useAtomValue(inAppBrowserAtom);
	const projectData = useAtomValue(singleAssetViewAtom);

	/**
	 * Find required data
	 * @example <caption>Find required data including a project's GLB/GLTF and thumbnail URL if not provided</caption>
	 * findRequiredData();
	 * // returns null (finds project's GLB/GLTF and thumbnail URL if not provided if exists)
	 */
	function findRequiredData() {
		// Only proceed if project has files
		if (!isEmpty(projectData?.files)) {
			// If no GLB/GLTF file provided, try and find one
			if (
				!projectGLB &&
				((projectData?.category && !['rig', 'animation'].includes(projectData.category)) ||
					!projectData?.category)
			) {
				projectGLB =
					projectData?.files?.find(
						// Project ends with .glb or .gltf extension
						(file: { name: string }) => file.name.endsWith('.glb') || file.name.endsWith('.gltf'),
					)?.downloadUrl ?? undefined;
			}

			// If no thumbnail provided, try and find one
			if (!thumbnailURL) {
				thumbnailURL = findProjectThumbnailUrl(projectData?.files ?? [], projectData?.thumbnailUrl);
			}
		} else if (!thumbnailURL) {
			// If no thumbnail provided and no project files given, use default
			thumbnailURL = findProjectThumbnailUrl([]);
		}
	}

	/**
	 * Generate default component props for either a 3D view or a static image
	 * @param {String} [type=image] Type of component to generate default props for (default "image")
	 * @returns {Object} Default component props
	 * @example <caption>Generate default component props for either a 3D view or a static image</caption>
	 * generateDefaultComponentProps("image");
	 * // returns {src: thumbnailURL, alt: `"${projectData?.title}"'s thumbnail`, title: `"${projectData?.title}"'s thumbnail`, className: `asset-thumbnail-modal`, loading: `lazy`}
	 */
	function defaultComponentOptions(type: 'image' | 'model' = 'image'): any {
		if (type === 'image') {
			return {
				src: thumbnailURL,
				alt: `"${projectData?.title}"'s thumbnail`,
				className: `asset-thumbnail-modal`,
				loading: `lazy`,
			};
		}
		if (type === 'model') {
			return {
				// ar: true,
				'ar-modes': 'webxr scene-viewer quick-look',
				'auto-rotate': true,
				'camera-controls': true,
				'enable-pan': true,
				'environment-image': 'neutral',
				'shadow-intensity': 0.75,
				'shadow-softness': 0.6,
				'xr-environment': true,
				alt: `"${projectData?.title}"'s 3D model`,
				autoplay: true,
				class: 'asset-model-viewer',
				id: 'project-model-viewer',
				loading: 'lazy',
				poster: thumbnailURL,
				src: projectGLB,
				renderMode: inApp ? 'performance' : 'quality',
			};
		}

		return {};
	}

	/**
	 * Handles static images and generates an <img> tag
	 * @param {Object} [customProps] Google Model Viewer's component props to override default props
	 * @returns {ReactElement} <img> tag for static images
	 * @example <caption>Handles static images and generates an <img> tag</caption>
	 * handleStaticImage();
	 * // returns <img />
	 */
	function handleStaticImage(customProps: any = {}): ReactElement | null {
		if (thumbnailURL) {
			/** Component props to use */
			const componentProps: any = {
				...defaultComponentOptions('image'),
				...customProps,
			};

			// eslint-disable-next-line jsx-a11y/alt-text
			return (
				<>
					<Image
						alt={componentProps?.alt ?? projectData?.title ?? 'Untitled Project'}
						data-testid='sav-thumbnail'
						fill
						src={componentProps?.src ?? thumbnailURL}
						{...componentProps}
					/>
					<ThumbnailButtons type='image' />
				</>
			);
		}

		return null;
	}

	/** Handles and generates Google Model Viewer's component */
	function handleGLB(
		/** Google Model Viewer's component props to override default props */
		customProps: any = {},
		onLoad?: (...args: any[]) => void | Promise<(...args: any[]) => void>,
	): ReactElement | null {
		if (projectGLB) {
			/** Component props to use */
			const componentProps: any = {
				...defaultComponentOptions('model'),
				...(customProps || {}),
			};

			return (
				<Suspense fallback={null}>
					<ModelViewer gmvData={componentProps} onLoad={onLoad} />
				</Suspense>
			);
		}

		return handleStaticImage();
	}

	/**
	 * Swap textures on model with ones given
	 * @example <caption>Swap textures on model with ones given</caption>
	 * swapTextures({diffuse: {src: 'https://...', aliases: ['diffuse', 'color', 'colour', 'base'], modelViewer: 'baseColorTexture'}});
	 * // returns null (will add the diffuse/color texture to the model if it exists)
	 * @async
	 */
	async function swapTextures(
		/** All textures to swap with */
		textures: any = {},
		/** Iterative call number - Used in instance that function called before model loaded */
		iterativeCall = 0,
		/** Max iterative call number */
		iterativeMax = 10,
	): Promise<void> {
		/** Currently loaded model */
		const model = document.querySelector('model-viewer');

		/** Model's materials */
		const material: any = model?.model?.materials[0];

		// Set textures
		if (material && model && textures) {
			// Set each texture
			await Promise.all(
				Object.entries(textures).map(async ([texture, values]: [any, any]) => {
					// Make sure texture exists
					if (values?.src && values?.modelViewer) {
						/** Generated Google Model Viewer's texture */
						const displayTexture = await model.createTexture(values.src);

						// If metallic or diffuse, apply to PBR, else, just apply to material
						if (
							(texture.includes('metallic') || texture.includes('diffuse') || texture.includes('mgo')) &&
							material?.pbrMetallicRoughness?.[values.modelViewer]
						) {
							// Set texture
							await material.pbrMetallicRoughness[values.modelViewer].setTexture(displayTexture);
							// Update metallic and roughness factor
							if (texture.includes('metallic') || texture.includes('mgo')) {
								material.pbrMetallicRoughness?.setMetallicFactor(0.25);
								material.pbrMetallicRoughness?.setRoughnessFactor(0.1);
							}
						} else if (material[values.modelViewer]) {
							await material[values.modelViewer].setTexture(displayTexture);
						}
					}
				}),
			);
		} else if (iterativeCall < iterativeMax) {
			// If no model or material, try again in 0.5 seconds up to 10 (iterativeMax) times
			setTimeout(() => {
				swapTextures(textures, iterativeCall + 1);
			}, 500);
		}
	}

	/**
	 * Handles generated GLB for materials
	 * @example <caption>Handles generated GLB for materials if none provided and set projectGLB to a plain sphere</caption>
	 * handleGLB();
	 * // returns null (set projectGLB variable to a plain sphere)
	 */
	function materialsGLB() {
		if (!projectGLB) {
			// If no GLB provided, use a plain sphere
			projectGLB =
				'https://firebasestorage.googleapis.com/v0/b/masterpiece-studio-staging-user-files/o/YXV0aDB8NjFiY2U2ZGQ2ODQ1NjkwMDcxOWZkYWJm%2F192%2Fplain_sphere.glb?alt=media&token=61a065ff-0e47-45ee-8811-33c10a70506f';
		}
	}

	/**
	 * Handles materials and textures to generates Google Model Viewer's component
	 * @param {Object} [customProps] Google Model Viewer's component props to override default props
	 * @returns {ReactElement} Google Model Viewer's component for materials and textures
	 */
	function handleMaterial(customProps: any = {}): ReactElement | null {
		/** Textures found */
		const allowedTextures: any = {
			/** Standard color */
			diffuse: {
				src: null,
				modelViewer: 'baseColorTexture',
			},
			/** Bump map for perceived depth */
			normal: {
				src: null,
				modelViewer: 'normalTexture',
			},
			/** Both roughness and metallic - Google Model Viewer combines these two */
			metallic: {
				src: null,
				modelViewer: 'metallicRoughnessTexture',
			},
			/** Ambient Occlusion - Shadow map/how light bounce */
			occlusion: {
				src: null,
				modelViewer: 'occlusionTexture',
			},
			/** How it will glow in the dark */
			emissive: {
				src: null,
				modelViewer: 'emissiveTexture',
			},
		};

		// Go through project files and find all textures
		if (!isEmpty(projectData?.files)) {
			projectData?.files?.forEach((file: any) => {
				if (['jpg', 'jpeg', 'png', 'webp'].includes(file?.name?.split('.')?.pop()?.toLowerCase())) {
					/** File name */
					const fileName: string = file.name.toLowerCase().trim();
					/** File name without extension */
					const fileNameWithoutExtension: string = fileName.substring(0, fileName.lastIndexOf('.'));

					// Check if file name matches any of the allowed texture names
					const textureName = assumeTextureType(
						fileNameWithoutExtension,
						projectData?.title || 'Untitled Project',
					);

					if (textureName && allowedTextures?.[textureName] && file?.downloadUrl) {
						allowedTextures[textureName].src = file.downloadUrl;
					}
				}
			});
		}

		// Generate GLB if no GLB found
		if (!projectGLB) {
			materialsGLB();
		}

		// Generate Google Model Viewer's component
		return handleGLB(
			{
				...customProps,
			},
			swapTextures(allowedTextures),
		);
	}

	/**
	 * Creates a Google Model Viewer's or image component for given project
	 */
	if (projectData?.id) {
		// If no GLB or thumbnail given, find one
		if (!projectGLB || !thumbnailURL) {
			findRequiredData();
		}

		// Decide which component to use
		if (projectData?.category === 'material' && !projectGLB) {
			display = handleMaterial();
		} else if (projectGLB) {
			display = handleGLB();
		} else {
			display = handleStaticImage();
		}
	}

	return (
		display && (
			<div className='asset-thumbnail-modal-container'>
				{/* Place image behind display */}
				{!inApp && (thumbnailURL || projectData?.thumbnailUrl) && (
					<Image
						alt={projectData?.title ?? 'Untitled Project'}
						className='sav-thumbnail-glow'
						data-testid='sav-thumbnail-glow'
						fill
						src={thumbnailURL ?? projectData?.thumbnailUrl ?? (defaultThumbnail?.src || defaultThumbnail)}
					/>
				)}

				{display}
			</div>
		)
	);
}
