import { rolesService } from '@mpx-sdk/api-client';
import { singleAssetViewAtom, userAtom } from '@mpx-sdk/shared/atoms';
import VerifiedAvatar from '@mpx-sdk/ui/components/profile/VerifiedAvatar';
import { Avatar, Stack, Typography } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { isEmpty } from 'lodash';
import Link from 'next/link';
import { type ReactElement, useEffect, useState } from 'react';

/** A component that displays a user's avatar and username */
export default function User(): ReactElement | null {
	const [projectData, setProjectData] = useAtom(singleAssetViewAtom);
	const [userData, setUserData] = useState<any>(null);
	const currentUser = useAtomValue(userAtom);
	const hideUsername = userData?.username === 'devops+delete';

	function generateUsernameInfo() {
		let userData = projectData?.user ?? projectData?.owner ?? null;

		if (!userData) {
			return null;
		}
		if (userData?.id === currentUser?.id) {
			userData = currentUser;
		}

		let displayName = userData?.displayName;

		if (!displayName) {
			if (userData?.useName && (userData?.firstName || userData?.lastName)) {
				displayName = `${userData?.firstName} ${userData?.lastName}`.trim();
			} else if (userData?.username) {
				displayName = userData?.username;
			} else {
				displayName = String(userData?.id);
			}
		}

		return (
			<Stack alignItems='flex-start' direction='column' justifyContent='flex-start'>
				<Typography
					sx={{
						whiteSpace: 'nowrap',
					}}
					variant='h6'
				>
					{displayName}
				</Typography>

				{!hideUsername && userData?.username && (
					<Typography
						sx={{
							whiteSpace: 'nowrap',
						}}
						variant='subtitle1'
					>
						@{userData?.username}
					</Typography>
				)}
			</Stack>
		);
	}

	async function generateDisplay() {
		if (!isEmpty(projectData)) {
			const userDataDisplay = projectData?.user ?? projectData?.owner ?? null;

			setUserData(userDataDisplay);

			if (userDataDisplay?.id && !userDataDisplay?.roles) {
				try {
					await rolesService.getUserRoles(userDataDisplay?.id).then((roles) => {
						userDataDisplay.roles = roles;
						setUserData(userDataDisplay);
					});
				} catch (error) {
					console.error(error);
				}
			}
		}

		return null;
	}

	useEffect(() => {
		if (!isEmpty(projectData)) {
			generateDisplay();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectData]);

	if (!userData) {
		return null;
	}

	const userDisplay = (
		<Stack alignItems='center' direction='row' justifyContent='flex-start' spacing={1}>
			<VerifiedAvatar publicFacing user={userData}>
				{/* Avatar */}
				<Avatar
					alt={`${userData?.name ?? 'User'}'s avatar`}
					aria-label={userData?.name}
					data-testid='sav-user-avatar'
					imgProps={{
						loading: 'lazy',
					}}
					src={userData?.photoUrl}
				/>
			</VerifiedAvatar>

			{/* Username */}
			{generateUsernameInfo()}
		</Stack>
	);

	return userData?.username ? (
		<Link
			href={`/user/${userData?.username}`}
			onClick={() => {
				setProjectData(null);
			}}
			style={{
				color: 'inherit',
			}}
		>
			{userDisplay}
		</Link>
	) : (
		userDisplay
	);
}
