'use client';

import { Button, Chip, FormControl } from '@mui/material';
import { useState } from 'react';

function UploadButton({
	label = '',
	children,
	inputProps = {},
	showChips = true,
	...rest
}: {
	label?: string;
	children: React.ReactNode;
	inputProps?: React.HTMLProps<HTMLInputElement>;
	showChips?: boolean;
} & React.ComponentProps<typeof Button>) {
	const id = `upload-button${Math.random().toString(36).substring(2, 9)}`;
	const { onChange, multiple, ...restInputProps } = inputProps;
	const [files, setFiles] = useState<File[] | null>([]);
	// Remove as it's not a valid prop for input of file type
	delete restInputProps.value;

	return (
		<>
			{showChips &&
				files?.map?.((file) => (
					<Chip
						key={file.name}
						label={file.name}
						onDelete={() => {
							setFiles(null);
							onChange?.(null);
						}}
					/>
				))}

			<FormControl fullWidth sx={{ mt: files ? 2 : 0 }}>
				<label htmlFor={id}>
					{label}

					<Button component='span' variant='contained' {...rest}>
						{children}
						<input
							hidden
							id={id}
							multiple={multiple}
							onChange={({ target }) => {
								setFiles(target.files?.length ? Array.from(target.files) : []);
								onChange?.(target.files?.length ? Array.from(target.files) : []);
							}}
							style={{ display: 'none' }}
							type='file'
							{...restInputProps}
						/>
					</Button>
				</label>
			</FormControl>
		</>
	);
}

export default UploadButton;
