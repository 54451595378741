'use client';

/* eslint-disable react-hooks/rules-of-hooks */
import { inAppBrowserAtom, store } from '@mpx-sdk/shared/atoms';
import { type Theme, useMediaQuery } from '@mui/material';
import { useAtomValue } from 'jotai';

/** This is a helper class for UI related functions */
class UIHelper {
	/**
	 * This is related to the responsive design of the app. It is used to determine whether the app is in mobile view or desktop view.
	 * If the app is in in-app browser, it will always return false
	 *
	 * true: desktop or larger screen views
	 *
	 * false: mobile, in-app or smaller screen view
	 * */
	static isResponsiveView() {
		const isInAppBrowser = useAtomValue(inAppBrowserAtom);
		if (isInAppBrowser) {
			return false;
		}
		return !useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
	}

	static isMobile() {
		return useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	}

	static isInAppBrowser() {
		return store.get(inAppBrowserAtom);
	}
}

export default UIHelper;
