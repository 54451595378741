'use client';

import { Alert, Snackbar } from '@mui/material';
import { type ReactElement } from 'react';

interface WarningSnackbarProps {
	/**
	 * Whether or not the warning snackbar is currently open.
	 * @default false
	 */
	isOpen?: boolean;

	/**
	 * The message to display in the warning snackbar.
	 * If not provided, a default message is shown.
	 */
	message?: string;

	/** A function to call when the warning snackbar is closed. */
	onClose?: () => void;
}

/**
 * A Snackbar component that displays a warning message to the user.
 * @returns {ReactElement} The rendered WarningSnackbar component.
 */
export default function WarningSnackbar({ message, isOpen = false, onClose }: WarningSnackbarProps): ReactElement {
	const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		onClose?.();
	};

	return (
		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			autoHideDuration={10000}
			onClose={handleClose}
			open={isOpen}
		>
			<Alert onClose={handleClose} severity='warning' sx={{ width: '100%' }} variant='filled'>
				{message ||
					'You are currently an admin and might be making changes to either the website or the database. Please be careful.'}
			</Alert>
		</Snackbar>
	);
}
