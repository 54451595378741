import { adminFeaturesAtom, userAtom, userRolesAtom } from '@mpx-sdk/shared/atoms';
import { type PublicAsset } from '@mpx-sdk/types';
import { InputAdornment, TextField } from '@mui/material/';
import { useAtomValue } from 'jotai';
import { type ReactElement, useEffect, useState } from 'react';

interface EditTextProps {
	enableAdmin?: boolean;
	maxChar?: number;
	projectData?: Partial<PublicAsset> | null;
	type: 'title' | 'description' | string;
}

export default function EditText({
	enableAdmin = true,
	maxChar = 512,
	projectData,
	type,
}: EditTextProps): ReactElement | null {
	const [inputValue, setInputValue] = useState(projectData?.[type] || '');
	const [isAdmin, setIsAdmin] = useState(false);
	const adminView = useAtomValue(adminFeaturesAtom);
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);

	useEffect(() => {
		setInputValue(projectData?.[type]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectData]);

	useEffect(() => {
		if (enableAdmin && currentUserRoles?.library && adminView && currentUser?.id !== projectData?.user?.id) {
			setIsAdmin(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return type && projectData ? (
		<TextField
			aria-label={`Update ${type} for ${projectData?.title}`}
			className={`sav-edit-text ${isAdmin ? 'admin-input' : ''}`}
			data-modal-entry-type={type}
			data-modal-project-id={`modal-project-${projectData?.id}`}
			InputProps={{
				endAdornment: (
					<InputAdornment className='textfield-char-count' position='end'>
						{`${inputValue?.length || '?'} / ${type === 'title' ? 64 : maxChar}`}
					</InputAdornment>
				),
			}}
			margin='normal'
			multiline
			onChange={(e) => setInputValue(e.target.value)}
			placeholder={projectData?.[type] || `Enter ${type}...`}
			size='medium'
			sx={{
				maxLength: type === 'title' ? 64 : 512,
				width: '100%',
				'.MuiFilledInput-root': {
					padding: '8px 12px',
				},
			}}
			value={inputValue || ''}
			variant='filled'
		/>
	) : null;
}
