/* eslint-disable no-nested-ternary */
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { Grid, IconButton, Typography } from '@mui/material';
import { type ReactElement, memo } from 'react';

interface OverlayContentProps {
	/** Type of click event to trigger */
	clickType?: 'download' | 'info' | 'delete';
	/** Icon to display */
	icon?: ReactElement;
	/** Click event handler */
	onClick?: (type?: 'download' | 'info' | 'delete') => void;
	/** Text to display */
	text?: string;

	/** The number of overlay content items to display */
	numberOfOverlayContent?: number;
}

/** A component that displays an overlay content with an icon and text. */
function OverlayContent({
	clickType,
	icon,
	onClick,
	text,
	numberOfOverlayContent,
}: Readonly<OverlayContentProps>): ReactElement | null {
	const responsiveView = UIHelper.isResponsiveView();

	return (
		<Grid
			key={`asset-media-overlay-content-${text}-${clickType}`}
			aria-label={`${clickType} button`}
			className='asset-media-overlay-content'
			item
			onClick={(e) => {
				e.preventDefault();

				if (onClick && clickType) {
					onClick(clickType);
				}
			}}
			onKeyDown={(event) => {
				event.preventDefault();

				if (onClick && clickType && (event.key === 'Enter' || event.key === ' ')) {
					onClick(clickType);
				}
			}}
			sm={responsiveView ? 'auto' : numberOfOverlayContent === 1 ? 12 : 6}
			sx={{
				cursor: onClick ? 'pointer' : 'default',
				fontSize: `clamp(0.6rem, 1.05rem, 1.3rem) !important`,
				padding: '0.5rem',
			}}
			xs={responsiveView ? 'auto' : numberOfOverlayContent === 1 ? 12 : 6}
		>
			{responsiveView ? (
				<IconButton
					aria-label={`${clickType} button`}
					sx={{
						marginBottom: '0.5rem',
					}}
				>
					{icon}
				</IconButton>
			) : (
				icon
			)}

			{!responsiveView && text && (
				<Typography
					sx={{
						fontSize: 'clamp(0.6rem, 1.05rem, 1.3rem) !important',
						fontWeight: 600,
					}}
					variant='h3'
				>
					{text}
				</Typography>
			)}
		</Grid>
	);
}

export default memo(OverlayContent);
