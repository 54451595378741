import { fastCommentsSSOAtom, inAppBrowserAtom, singleAssetViewAtom } from '@mpx-sdk/shared/atoms';
import { urls } from '@mpx-sdk/shared/configs';
import Env from '@mpx-sdk/shared/configs/env';
import { FastCommentsCommentWidget } from 'fastcomments-react';
import { useAtomValue } from 'jotai';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/navigation';

export default function Comments() {
	const projectData = useAtomValue(singleAssetViewAtom);
	const fastCommentsSSO = useAtomValue(fastCommentsSSOAtom);
	const isInAppBrowser = useAtomValue(inAppBrowserAtom);
	const router = useRouter();

	return (
		<div style={{ width: '100%' }}>
			{projectData?.id && (
				<FastCommentsCommentWidget
					disableSuccessMessage
					hasDarkBackground
					sso={
						fastCommentsSSO || {
							loginCallback: () => {
								if (isInAppBrowser) {
									router.push(urls.routes.deviceLogin);
								} else {
									signIn('auth0');
								}
							},
						}
					}
					tenantId={Env.FASTCOMMENTS.CLIENT_ID as string}
					urlId={projectData.id?.toString()}
				/>
			)}
		</div>
	);
}
