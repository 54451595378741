'use client';

/* eslint-disable no-nested-ternary */
import { matureWordsFilter } from '@mpx-sdk/helpers/input';
import { savEditMode, singleAssetViewAtom, userAtom } from '@mpx-sdk/shared/atoms';
import EditText from '@mpx-sdk/ui/components/single-asset-view/components/EditText';
import { Box, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { isEmpty } from 'lodash';
import Link from 'next/link';
import { type ReactElement } from 'react';

export default function Description(): ReactElement | null {
	const currentUser = useAtomValue(userAtom);
	const editMode = useAtomValue(savEditMode);
	const projectData = useAtomValue(singleAssetViewAtom);

	let displayText: string | ReactElement = projectData?.description || 'No description provided';

	if (projectData?.description) {
		// Apply mature filter if applicable
		if ((currentUser?.matureFilter || !currentUser) && typeof displayText === 'string' && !isEmpty(displayText)) {
			displayText = matureWordsFilter(displayText);
		}

		const linkRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
		const linkMatches = displayText.match(linkRegex);

		if (linkMatches && typeof displayText === 'string') {
			const parts: (string | ReactElement)[] = [];
			let lastIndex = 0;

			// eslint-disable-next-line no-restricted-syntax
			for (const match of linkMatches) {
				const index = displayText.indexOf(match, lastIndex);
				if (index !== -1) {
					parts.push(displayText.substring(lastIndex, index));
					parts.push(
						<Link key={match} href={match} rel='noopener noreferrer' target='_blank'>
							{match}
						</Link>,
					);
					lastIndex = index + match.length;
				}
			}

			if (lastIndex < displayText.length) {
				parts.push(displayText.substring(lastIndex));
			}

			// eslint-disable-next-line react/jsx-no-useless-fragment
			displayText = <>{parts}</>;
		}
	}

	return projectData?.id ? (
		<Stack
			alignItems='flex-start'
			direction='column'
			justifyContent='flex-start'
			spacing={2}
			sx={{
				marginBottom: '1rem',
			}}
		>
			{editMode && (
				<Box
					sx={{
						width: '100%',
					}}
				>
					<Typography
						className='sav-section-headers'
						gutterBottom
						sx={{
							marginTop: '1rem',
						}}
						variant='h6'
					>
						Edit Title
					</Typography>

					{editMode ? (
						<EditText projectData={projectData} type='title' />
					) : (
						<Typography
							gutterBottom
							sx={{
								a: { color: (theme) => `${theme.palette.text.action} !important` },
								whiteSpace: 'pre-line',
							}}
							variant='body1'
						>
							{!isEmpty(displayText) ? JSON.stringify(displayText) : 'No description provided'}
						</Typography>
					)}
				</Box>
			)}

			{/* General description */}
			{(projectData?.type !== 'history' || projectData?.description) && (
				<Box
					sx={{
						width: '100%',
					}}
				>
					<Typography
						className='sav-section-headers'
						gutterBottom
						sx={{
							marginTop: '1rem',
						}}
						variant='h6'
					>
						Description
					</Typography>

					{editMode ? <EditText projectData={projectData} type='description' /> : displayText}
				</Box>
			)}

			{/* Gen AI - Initial prompt */}
			{projectData?.metadata?.genAIData?.initialPrompt && (
				<Box>
					<Typography
						className='sav-section-headers'
						gutterBottom
						sx={{
							marginTop: '1rem',
						}}
						variant='h6'
					>
						Initial prompt
					</Typography>

					{projectData?.metadata?.genAIData?.initialPrompt}
				</Box>
			)}

			{/* Gen AI - Shape */}
			{projectData?.metadata?.genAIData?.Shape && (
				<Box>
					<Typography
						className='sav-section-headers'
						gutterBottom
						sx={{
							marginTop: '1rem',
						}}
						variant='h6'
					>
						Shape
					</Typography>

					{projectData.metadata.genAIData.Shape}
				</Box>
			)}

			{/* Gen AI - Paint prompt */}
			{projectData?.metadata?.genAIData?.['Paint Looks Like'] && (
				<Box>
					<Typography
						className='sav-section-headers'
						gutterBottom
						sx={{
							marginTop: '1rem',
						}}
						variant='h6'
					>
						Paint prompt
					</Typography>

					{projectData?.metadata?.genAIData?.['Paint Looks Like']}
				</Box>
			)}

			{/* Gen AI - Negative paint prompt */}
			{projectData?.metadata?.genAIData?.['Paint Does Not Look Like'] && (
				<Box>
					<Typography
						className='sav-section-headers'
						gutterBottom
						sx={{
							marginTop: '1rem',
						}}
						variant='h6'
					>
						Negative prompt
					</Typography>

					{projectData?.metadata?.genAIData?.['Paint Does Not Look Like']}
				</Box>
			)}

			{/* Gen AI - Animation prompt */}
			{projectData?.metadata?.genAIData?.Animation && (
				<Box>
					<Typography
						className='sav-section-headers'
						gutterBottom
						sx={{
							marginTop: '1rem',
						}}
						variant='h6'
					>
						Animation prompt
					</Typography>

					{projectData?.metadata?.genAIData?.Animation}
				</Box>
			)}
		</Stack>
	) : null;
}
