import { genAIService, userService } from '@mpx-sdk/api-client';
import { downloadProjectFiles, isProjectDownloadable, toggleLikeProject } from '@mpx-sdk/helpers/assets';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { BookmarkIcon, LoadIcon, OpenIcon, RemixIcon, ShareIcon, VRIcon } from '@mpx-sdk/images';
import {
	adminFeaturesAtom,
	availableCreditsAtom,
	inAppBrowserAtom,
	openShareDialogAtom,
	singleAssetViewAtom,
	userAtom,
	userHistoryAtom,
	userRolesAtom,
} from '@mpx-sdk/shared/atoms';
import { urls } from '@mpx-sdk/shared/configs';
import { downloadFile } from '@mpx-sdk/shared/utils';
import DeleteAsset from '@mpx-sdk/ui/components/delete-asset/DeleteAsset';
import SaveToGalleryButton from '@mpx-sdk/ui/components/single-asset-view/components/SaveToGalleryButton';
import { ArrowDropDown } from '@mui/icons-material';
import { Box, Grid, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { debounce, find, findIndex, isNumber } from 'lodash';
import Link from 'next/link';
import { type ReactElement, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

const moreInformationMapping = [
	{
		key: 'looksLikePrompt',
		label: 'Shape',
	},
	{
		key: 'promptPos',
		label: 'Paint Looks Like',
	},
	{
		key: 'promptNeg',
		label: 'Paint Does Not Look Like',
	},
	{
		key: 'movesLikePrompt',
		label: 'Animation',
	},
];

enum FileType {
	GLB = 'glb',
	FBX = 'fbx',
	USDZ = 'usdz',
}

const DownloadOptions = [
	{
		label: 'Download as .FBX',
		value: FileType.FBX,
	},
	{
		label: 'Download as .GLB',
		value: FileType.GLB,
	},
	{
		label: 'Download as .USDZ',
		value: FileType.USDZ,
	},
];

interface InteractionsProps {
	/** Use this to override the default download function */
	onDownload?: () => void;
	/** If the interactions should be left aligned or not */
	leftAligned?: boolean;
}

/** A component that displays the interactions (download, share, etc...) for a project. */
export default function Interactions({
	onDownload,
	leftAligned = true,
}: Readonly<InteractionsProps>): ReactElement | null {
	const [isBookmarked, setIsBookmarked] = useState(false);
	const [projectData, setProjectData] = useAtom(singleAssetViewAtom);
	const adminView = useAtomValue(adminFeaturesAtom);
	const availableCredits = useAtomValue(availableCreditsAtom);
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);
	const inApp = useAtomValue(inAppBrowserAtom);
	const setOpenSocialSharing = useSetAtom(openShareDialogAtom);
	const userHistory = useAtomValue(userHistoryAtom);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isOpen, setIsOpen] = useState(false);

	/** If the project is downloadable or not. */
	const projectDownloadable = useMemo(() => isProjectDownloadable(projectData ?? null) ?? false, [projectData]);

	/** The different options for the download button. */
	const displayOptions = useMemo(
		() => ({
			remix: {
				icon: <RemixIcon />,
				text: 'Remix',
				title: `Remix ${projectData?.title ?? 'this asset'} and make it your own`,
			},
			load: {
				icon: <LoadIcon />,
				text: 'Load',
				title: `Load ${projectData?.title ?? 'this asset'} type into your project`,
			},
			open: {
				icon: <OpenIcon />,
				text: 'Open',
				title: `Open and continue editing ${projectData?.title ?? 'this asset'}`,
			},
			download: {
				icon: <LoadIcon />,
				text: 'Download',
				title: `Download ${projectData?.title ?? 'this asset'} locally to your computer`,
			},
		}),
		[projectData?.title],
	);

	/** The button option to display. */
	const buttonOption = useMemo(() => {
		if (projectDownloadable && projectData) {
			if (inApp) {
				if (projectData.category === 'model') {
					if (projectData.tags?.includes('stamp')) {
						return displayOptions.load;
					}

					return displayOptions.remix;
				}

				if (projectData.path) {
					return displayOptions.open;
				}

				if (projectData.category) {
					return displayOptions.load;
				}
			} else if (!projectData.path) {
				return displayOptions.download;
			}
		}

		return null;
	}, [displayOptions, inApp, projectData, projectDownloadable]);

	const handleDownloadMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		setIsOpen(!isOpen);
	};

	const handleDownloadMenuClose = () => {
		setAnchorEl(null);
		setIsOpen(false);
	};

	async function downloadHistoryAsset(data: any, fileType: string = FileType.GLB) {
		handleDownloadMenuClose();
		const projectTitle = data?.title || data?.projectTitle || 'Untitled_project';
		/** Toast loading ID */
		const toastID = toast.loading(`Downloading "${projectTitle}"`, {
			closeOnClick: true,
			draggable: true,
		});

		try {
			toast.update(toastID, {
				render: `Downloading "${projectTitle}": Initializing download`,
				isLoading: true,
				autoClose: false,
			});

			const res = await genAIService.downloadGeneratedModel(data.id);

			if (res?.[fileType] && !(window?.vuplex || inApp)) {
				downloadFile(res?.[fileType], `${projectTitle}.${fileType}`);

				DataLayer.triggerMeasurementEvent('downloadEvent', {
					event_name: 'download',
					status: 'success',
					id: data.id,
					user_id: currentUser?.id,
					user_number_of_created_models: userHistory?.length ?? 0,
					...availableCredits,
				});
			} else {
				await downloadProjectFiles(projectData, inApp);

				DataLayer.triggerMeasurementEvent('downloadEvent', {
					event_name: 'download',
					status: 'success',
					id: data.id,
					user_id: currentUser?.id,
					user_number_of_created_models: userHistory?.length ?? 0,
					...availableCredits,
				});
			}

			toast.update(toastID, {
				autoClose: 5000,
				isLoading: false,
				render: `${window?.vuplex || inApp ? 'Importing' : 'Downloading'} complete for "${projectTitle}"`,
				type: 'success',
			});
		} catch (error: any) {
			console.error(error?.message || error);

			toast.update(toastID, {
				render: `Error downloading files for "${projectTitle}": ${error?.message || error}`,
				type: 'error',
				isLoading: false,
				autoClose: 120000,
			});

			DataLayer.triggerMeasurementEvent('downloadEvent', {
				event_name: 'download',
				status: 'failed',
				id: data.id,
				user_id: currentUser?.id,
			});
		}
	}
	const debouncedDownloadHistoryAsset = debounce(downloadHistoryAsset, 3000);

	async function getBookmarkData() {
		if (currentUser) {
			const myBookmarks = await userService.getMyBookmarks();
			const isBookmarked = find(myBookmarks, { id: projectData.id });
			setIsBookmarked(!!isBookmarked);
		} else {
			setIsBookmarked(false);
		}
	}

	const remixButton = useMemo(
		() => (
			<IconButton
				aria-label='View asset in Meta Quest VR app'
				onClick={() => {
					if (inApp) {
						if (projectData?.type === 'history') {
							debouncedDownloadHistoryAsset(projectData);
						} else if (onDownload) {
							onDownload();
						} else {
							downloadProjectFiles(projectData ?? null, inApp);
						}
					}
				}}
				sx={{
					borderRadius: '24px !important',
					flexShrink: 0,
					margin: 'auto 0',
					padding: '0.5rem 1rem',
					whiteSpace: 'nowrap',
				}}
			>
				<Stack alignItems='center' direction='row' justifyContent='center' spacing={1}>
					<VRIcon
						sx={{
							width: '1.5rem',
							height: '1.5rem',
							margin: '1% 2.5%',
						}}
					/>

					<Typography
						sx={{
							fontSize: 'clamp(0.8rem, 1rem, 1rem)',
							fontWeight: 600,
							letterSpacing: '0.035px',
							textAlign: 'center',
						}}
					>
						Remix
						<Box
							component='span'
							sx={{
								display: { xs: 'none', sm: 'initial' },
								textDecoration: 'none',
								whiteSpace: 'nowrap',
								'&::before': {
									content: '""',
									display: 'inline-block',
									height: '100%',
									verticalAlign: 'middle',
								},
							}}
						>
							{' '}
							in VR
						</Box>
					</Typography>
				</Stack>
			</IconButton>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[inApp, projectData],
	);

	useEffect(() => {
		if (isNumber(projectData?.id)) {
			getBookmarkData();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectData]);

	return (
		<Grid
			alignItems='center'
			container
			data-testid='sav-interactions-button'
			direction='row'
			item
			justifyContent={leftAligned ? 'flex-start' : 'flex-end'}
			spacing={1}
			sx={{
				width: '100%',
				button: {
					color: 'text.primary',
					fontSize: 'clamp(0.8rem, 1.2rem, 1.2rem)',
					height: '100%',
					margin: 'auto',
					width: 'auto',
					svg: {
						fontSize: 'clamp(0.8rem, 1.2rem, 1.2rem)',
					},
					'&.MuiIconButton-root': {
						backgroundColor: 'background.dark',
						border: (theme) => `1px solid ${theme.palette.background.border}`,
						borderRadius: '6px',
						'&:hover': {
							backgroundColor: 'primary.light',

							color: 'text.contrast',
							svg: {
								color: '#000 !important',
							},
						},
					},
				},
				'.MuiGrid-item': {
					marginRight: '0.5rem',
				},
			}}
		>
			{isNumber(projectData?.id) && (
				<Grid item>
					<Tooltip arrow describeChild title='Share this project with others'>
						<IconButton aria-label='Share project' onClick={() => setOpenSocialSharing(true)}>
							<ShareIcon />
						</IconButton>
					</Tooltip>
				</Grid>
			)}

			{/* Download menu */}
			{buttonOption && projectDownloadable && !inApp && (
				<Grid item>
					{projectData?.type === 'history' ? (
						<>
							<MenuItem
								onClick={handleDownloadMenuClick}
								sx={{
									border: (theme) => `1px solid ${theme.palette.background.border}`,
									borderRadius: '20px',
									px: 1.5,
									'&:hover': {
										backgroundColor: (theme) => theme.palette.original.main,
									},
								}}
							>
								<Stack direction='row'>
									{buttonOption.icon}

									<ArrowDropDown
										sx={{
											color: (theme) => theme.palette.text.primary,
											transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
											transition: 'transform 0.3s ease-in-out',
										}}
									/>
								</Stack>
							</MenuItem>
							<Menu
								anchorEl={anchorEl}
								id='download-menu'
								onClose={handleDownloadMenuClose}
								open={Boolean(anchorEl)}
							>
								{DownloadOptions.map((option) => (
									<MenuItem
										key={option.value}
										onClick={() => {
											downloadHistoryAsset(projectData, option.value);
										}}
									>
										{option.label /* (fileSize) TODO: add file size to the label */}
									</MenuItem>
								))}
							</Menu>
						</>
					) : (
						<Tooltip arrow describeChild title={buttonOption.title}>
							<IconButton
								aria-label={buttonOption.title}
								data-cypress={`button-${buttonOption.text.toLowerCase()}`}
								onClick={() => {
									if (projectData?.type === 'history') {
										debouncedDownloadHistoryAsset(projectData);
									} else if (onDownload) {
										onDownload();
									} else {
										downloadProjectFiles(projectData ?? null, inApp);
									}
								}}
							>
								{buttonOption.icon}
							</IconButton>
						</Tooltip>
					)}{' '}
				</Grid>
			)}

			{currentUser && projectData && (
				<>
					{currentUser?.id !== projectData?.user?.id &&
						currentUser?.id !== projectData?.owner?.id &&
						!inApp && (
							<Grid item>
								<Tooltip
									arrow
									describeChild
									title={`${isBookmarked ? 'Unbookmark' : 'Bookmark'} project`}
								>
									<IconButton
										aria-label={`${isBookmarked ? 'Unbookmark' : 'Bookmark'} project`}
										onClick={() => {
											toggleLikeProject(Number(projectData?.id), !isBookmarked);

											setProjectData({
												...projectData,
												bookmarked: !isBookmarked,
											});
											setIsBookmarked(!isBookmarked);
										}}
										sx={{
											svg: {
												fill: isBookmarked ? 'text.primary' : 'transparent',
											},
										}}
									>
										<BookmarkIcon />
									</IconButton>
								</Tooltip>
							</Grid>
						)}

					{(projectData?.type === 'history' ||
						((projectData?.user?.id === currentUser?.id || projectData?.owner?.id === currentUser?.id) &&
							!isNumber(projectData?.id)) ||
						(currentUserRoles?.library && adminView)) && (
						<Grid item>
							<DeleteAsset
								projectData={projectData}
								sx={{
									'&:hover': {
										backgroundColor: (theme) => `${theme.palette.error.main} !important`,
									},
								}}
							/>
						</Grid>
					)}
				</>
			)}

			{projectDownloadable && (
				<Grid item>
					{inApp ? (
						remixButton
					) : (
						<Link href={urls.app.oculus} rel='noopener noreferrer' target='_blank'>
							{remixButton}
						</Link>
					)}
				</Grid>
			)}

			{projectData?.type === 'history' && (
				<Grid item>
					<SaveToGalleryButton
						metadata={Object.keys(projectData?.originalData?.data)
							.map((key) => {
								const index = findIndex(moreInformationMapping, { key });

								if (index !== -1) {
									return `${moreInformationMapping[index].label}: ${projectData?.originalData.data[key]}`;
								}

								return null;
							})
							.filter(Boolean)}
						src={
							projectData?.originalData?.steps?.find((step: { stage: string }) => step.stage === 'export')
								?.outputFile
						}
						title={projectData?.title || String(projectData?.id)}
					/>
				</Grid>
			)}
		</Grid>
	);
}
