'use client';

import { userService } from '@api-client/index';
import projectService from '@api-client/services/assets.service';
import { request } from '@mpx-sdk/helpers/api';
import { genaiFirebase, updateUserProjectSlots } from '@mpx-sdk/helpers/assets';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { DeleteIcon } from '@mpx-sdk/images';
import {
	activeTeamAtom,
	adminFeaturesAtom,
	libraryItemDelete,
	singleAssetViewAtom,
	userAtom,
	userRolesAtom,
	userSlotsAtom,
} from '@mpx-sdk/shared/atoms';
import { type AssetSlots, type PublicAsset } from '@mpx-sdk/types';
import OverlayContent from '@mpx-sdk/ui/components/assets/card/overlay/OverlayContent';
import ConfirmDelete from '@mpx-sdk/ui/components/delete-asset/ConfirmDelete';
import { Button, IconButton, type IconButtonProps, Tooltip } from '@mui/material';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { type ReactElement, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

interface DeleteAssetProps {
	projectData: PublicAsset;
	/** If want to give custom function upon click delete, it would be given here  */
	onClick?: () => void;
	/** If want to give custom function upon closing the delete module, it would be given here  */
	onClose?: () => void;
	/** Size of the delete icon */
	size?: 'small' | 'medium' | 'large';
	/** Whether to show the delete button as an overlay or hovering icon */
	stylingType?: 'icon' | 'overlay' | 'button';
	/** Whether the button should be displayed using default conditions [true, default] or by force [false] */
	conditionalDisplay?: boolean;
	/** Custom styling for the icon button */
	sx?: IconButtonProps['sx'];
}

/**
 * Displays a delete icon which, will delete an asset regardless of location (public, private or continue)
 * @returns {ReactElement} A delete icon button which deletes an asset based on given project data
 */
export default function DeleteAsset({
	conditionalDisplay = true,
	onClick,
	onClose,
	projectData,
	size = 'medium',
	stylingType = 'icon',
	sx = {},
}: DeleteAssetProps): ReactElement | null {
	const db = useMemo(() => getFirestore(), []);
	const toastID = useRef<any>(null);

	// Current user data
	const [slots, setSlots] = useAtom(userSlotsAtom);
	const activeTeam = useAtomValue(activeTeamAtom);
	const adminView = useAtomValue(adminFeaturesAtom);
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);
	// Project data
	const setProjectData = useSetAtom(singleAssetViewAtom);
	// Library and page data
	const setLibraryItemDeleteState = useSetAtom(libraryItemDelete);

	// Dialogs
	const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);

	// Analysis related functions
	/**
	 * Determine the source of the project (library, continue or private) based on project path, source or ID
	 * @returns {string} 'library' (public gallery), 'continue' (projects from in-app) or 'private' (projects from private imports/exports)
	 * @example <caption>Assuming the projectData is set, and a ID is a number with no path set</caption>
	 * determineSourceType()
	 * // returns 'library'
	 */
	function determineSourceType(): 'continue' | 'private' | 'library' | 'history' | null {
		if (projectData) {
			if (projectData?.type === 'history') {
				return 'history';
			}

			if (projectData?.path) {
				return 'continue';
			}
			if (Number.isNaN(Number(projectData?.id))) {
				if (!projectData?.source) {
					return 'continue';
				}

				return 'private';
			}
			return 'library';
		}

		return null;
	}

	/** Where the source of the project is assumed to be (library, continue or private) */
	const sourceType: 'continue' | 'private' | 'library' | 'history' | null = determineSourceType();
	/** Whether the project is deletable or not (admin overrides ownership in library) */
	const canDelete =
		projectData &&
		sourceType &&
		(sourceType === 'history' ||
			sourceType === 'continue' ||
			(sourceType === 'private' &&
				projectData.source &&
				!['mpx', 'MPX', 'MPX GenAI'].includes(projectData.source)) ||
			(currentUserRoles?.library && (adminView || !conditionalDisplay)));
	// ! @AlexJSully: The temporary ability to prevent deletion of exports is my current solution to the issue of export count being dynamically updated rather than being a static value in the database.

	/** Creates toast notification, closes delete dialog, update slots and if a SAV is open, close that too
	 * Records the event to the data layer. */
	async function successfullyDeletedProject() {
		toast.update(toastID.current, {
			autoClose: 5000,
			isLoading: false,
			render: `Successfully deleted asset: "${projectData?.title || 'Untitled Project'}"`,
			type: 'success',
		});

		onClose?.();

		// Track the event
		DataLayer.triggerMeasurementEvent('assetEvent', {
			event_name: 'project_delete',
			project_id: projectData?.id ?? '',
		});

		setProjectData(null);

		const newSlots: AssetSlots | null = await userService.getMySlots();
		setSlots(newSlots);

		setTimeout(() => {
			setLibraryItemDeleteState(true);
		}, 500);
	}

	function failedToDeleteProject(errorMessage?: string) {
		toast.update(toastID.current, {
			render: errorMessage ?? `Error deleting asset: "${projectData?.title || 'Untitled Project'}"`,
			type: 'error',
			isLoading: false,
			autoClose: 120000,
		});

		// Revert the delete CSS properties
		const deleteParent = document.querySelector(
			`[data-grid-item-id="asset-grid-item-${projectData.id}-${projectData?.title || 'untitled'}"]`,
		) as HTMLDivElement;
		if (deleteParent) {
			deleteParent.style.pointerEvents = 'initial';
			deleteParent.style.opacity = 'initial';
		}
	}

	// Functions related to actually deleting projects
	async function deleteHistoryProject() {
		if (sourceType === 'history') {
			try {
				await deleteDoc(doc(getFirestore(genaiFirebase), 'ml-requests', projectData?.originalData?.id));

				successfullyDeletedProject();
			} catch (error) {
				failedToDeleteProject();
				console.error(`Error deleting asset: `, error);
			}
		}
	}

	/**  Delete the project from the user's continue list in Firestore */

	async function deleteContinueProject(): Promise<void> {
		if (
			typeof projectData?.id === 'string' &&
			typeof activeTeam?.id === 'number' &&
			(projectData?.user?.id === currentUser?.id || projectData?.owner?.id === currentUser?.id) &&
			sourceType === 'continue'
		) {
			// Delete the project from the user's continue list in Firestore
			await deleteDoc(doc(db, `projects/${activeTeam?.id}/team/${projectData?.id}`))
				.then(() => {
					updateUserProjectSlots((slots?.slotsProjectsUsed ?? 1) - 1);

					successfullyDeletedProject();
				})
				.catch((error) => {
					console.error(`Error deleting continue project ${projectData?.id}: `, error);
					failedToDeleteProject('Error deleting project');
				});
		} else {
			failedToDeleteProject();
			console.error(
				'Error deleting continue project - cause by invalid project data or user not being the owner',
				projectData,
			);
		}
	}

	async function deletePrivateProject() {
		if (
			typeof projectData?.id === 'string' &&
			currentUser?.auth0Id &&
			(projectData?.user?.id === currentUser?.id || projectData?.owner?.id === currentUser?.id) &&
			sourceType === 'private'
		) {
			// This will delete all files in the folder, including the project.json file
			try {
				await request(`/my-space/assets/${projectData?.id}`, 'DELETE');

				successfullyDeletedProject();
			} catch (error) {
				failedToDeleteProject();
				console.error(`Error deleting private import project ${projectData?.id}: `, error);
			}
		}
	}

	async function deleteLibraryProject() {
		if (sourceType === 'library') {
			try {
				await projectService.deletePublicProject(projectData.id);

				successfullyDeletedProject();
			} catch (error) {
				failedToDeleteProject();
				console.error(`Error deleting asset: `, error);
			}
		}
	}

	function deleteAsset() {
		toastID.current = toast.loading(`Deleting "${projectData.id}-${projectData?.title || 'untitled'}"`, {
			closeOnClick: true,
			draggable: true,
		});

		if (canDelete) {
			// Disable the delete button
			const deleteButton = document.querySelector(
				`[data-grid-item-id="asset-grid-item-${projectData.id}-${projectData?.title || 'untitled'}"]`,
			) as HTMLDivElement;
			if (deleteButton) {
				deleteButton.style.pointerEvents = 'none';
				deleteButton.style.opacity = '0.5';
			}

			toast.update(toastID.current, {
				render: `Deleting ${projectData?.title || 'untitled'}`,
				isLoading: true,
				autoClose: false,
			});

			if (sourceType === 'history') {
				deleteHistoryProject();
			} else if (sourceType === 'continue') {
				deleteContinueProject();
			} else if (sourceType === 'private') {
				deletePrivateProject();
			} else if (sourceType === 'library') {
				deleteLibraryProject();
			}
		} else {
			failedToDeleteProject('This project cannot be deleted.');
		}
	}

	// Handler functions
	const handleClickIcon = () => {
		if (onClick) {
			onClick();
		} else {
			setOpenConfirmDelete(true);
		}
	};

	return canDelete &&
		(currentUser?.id === projectData?.user?.id ||
			currentUser?.id === projectData?.owner?.id ||
			(currentUserRoles?.library && (adminView || !conditionalDisplay))) ? (
		<>
			{stylingType === 'icon' && (
				<Tooltip arrow describeChild title={`Delete "${projectData?.title || 'Untitled Project'}"`}>
					<IconButton
						key={`delete-${projectData?.id}`}
						aria-label='delete'
						className={`delete-icon ${
							currentUserRoles?.library && (adminView || !conditionalDisplay) && sourceType === 'library'
								? 'admin-edit'
								: ''
						}`}
						onClick={handleClickIcon}
						size={size}
						sx={sx}
					>
						<DeleteIcon key={`delete-${projectData?.id}-icon`} />
					</IconButton>
				</Tooltip>
			)}

			{stylingType === 'overlay' && (
				<OverlayContent
					key={`overlay-content-delete-${projectData?.id}`}
					aria-label='DELETE'
					clickType='delete'
					icon={<DeleteIcon key={`delete-${projectData?.id}-icon`} />}
					onClick={handleClickIcon as any}
					text='DELETE'
				/>
			)}

			{stylingType === 'button' && (
				<Button
					aria-label='Delete project'
					className='sav-header-open-button'
					onClick={handleClickIcon}
					startIcon={<DeleteIcon />}
					sx={{
						...sx,
						backgroundColor: 'background.paper',
						color: 'text.primary',
						padding: '2px 22px',
						textTransform: 'none',
						'&:hover': {
							backgroundColor: 'primary.light',
							color: 'text.contrast',
							svg: {
								color: '#000 !important',
							},
						},
					}}
					variant='contained'
				>
					Delete
				</Button>
			)}

			<ConfirmDelete
				key={`confirm-delete-${projectData?.id}`}
				currentUser={currentUser}
				deleteFunction={() => {
					deleteAsset();
				}}
				isOpen={openConfirmDelete}
				onClose={() => {
					setOpenConfirmDelete(false);
				}}
				projectTitle={projectData?.title}
				sourceType={sourceType}
			/>
		</>
	) : null;
}
