import { flagDialogOpenAtom, flagProjectDataAtom, userAtom } from '@mpx-sdk/shared/atoms';
import { type PublicAsset } from '@mpx-sdk/types';
import FlagButton from '@mpx-sdk/ui/components/flag/FlagButton';
import { useAtomValue, useSetAtom } from 'jotai';
import { type ReactElement, memo, useCallback } from 'react';

interface FlagProps {
	/** The project data to be flagged. */
	projectData: PublicAsset;
}

/** A component that displays a flag button to allow users to flag a project. */
function Flag({ projectData }: FlagProps): ReactElement | null {
	const currentUser = useAtomValue(userAtom);

	// Jotai atoms to control flagging dialog and selected project data.
	const setFlagDialogOpen = useSetAtom(flagDialogOpenAtom);
	const setFlagProjectData = useSetAtom(flagProjectDataAtom);

	/** Handles clicking the flag button. */
	const handleClickingFlag = useCallback(() => {
		setFlagDialogOpen(true);
		setFlagProjectData(projectData);
	}, [projectData, setFlagDialogOpen, setFlagProjectData]);

	return currentUser && projectData?.id ? (
		<FlagButton
			key={`flag-asset-button-${projectData?.id}`}
			onClick={handleClickingFlag}
			projectData={projectData}
		/>
	) : null;
}

export default memo(Flag);
