import { FlagIcon } from '@mpx-sdk/images';
import { type PublicAsset } from '@mpx-sdk/types';
import { IconButton, Tooltip } from '@mui/material';
import { type ReactElement, memo } from 'react';

interface FlagButtonProps {
	/** A callback function to be executed when the button is clicked */
	onClick?: () => void;
	/** The project data to be used to display the tooltip */
	projectData?: PublicAsset;
}

/** A button to flag a project */
function FlagButton({ onClick, projectData }: FlagButtonProps): ReactElement {
	return (
		<Tooltip arrow describeChild title={`Flag ${projectData?.title ?? 'this project'}`}>
			<IconButton
				aria-label={`Flag ${projectData?.title ?? 'this project'}`}
				onClick={() => {
					onClick?.();
				}}
				size='small'
			>
				<FlagIcon />
			</IconButton>
		</Tooltip>
	);
}

export default memo(FlagButton);
