'use client';

import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { type ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

interface IShowDialogProps {
	title?: string;
	content?: ReactNode;
	actions?: ReactNode;
	color?: string;
	maxWidth?:
		| string
		| {
				xs?: string;
				sm?: string;
				lg?: string;
				md?: string;
		  };
	maxHeight?:
		| string
		| {
				xs?: string;
				sm?: string;
				lg?: string;
				md?: string;
		  };
}

interface IDialogServiceContext {
	open: boolean;
	title: string;
	content: ReactNode;
	actions: ReactNode;
	color: string;
	hideDialog: () => void;
	showDialog: ({ title, content, actions, color }: IShowDialogProps) => void;
}

export const DialogServiceContext = createContext<IDialogServiceContext>({
	open: false,
	title: '',
	content: null,
	actions: null,
	color: '',
	hideDialog: () => {},
	showDialog: () => {},
});

export const useDialogService = () => useContext(DialogServiceContext);

export function DialogServiceProvider({ children }: { children: ReactNode }) {
	const [actions, setActions] = useState<ReactNode | null>(null);
	const [color, setColor] = useState<string>('');
	const [content, setContent] = useState<ReactNode | null>(null);
	const [maxHeight, setMaxHeight] = useState<IShowDialogProps['maxWidth']>(undefined);
	const [maxWidth, setMaxWidth] = useState<IShowDialogProps['maxWidth']>('600px');
	const [open, setOpen] = useState(false);
	const [title, setTitle] = useState('');

	const hideDialog = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	const showDialog = useCallback(
		({ title, content, actions, color, maxWidth = '600px', maxHeight }: IShowDialogProps) => {
			setTitle(title ?? '');
			setContent(content);
			setActions(actions);
			setColor(color ?? '');
			setOpen(true);
			setMaxWidth(maxWidth);
			setMaxHeight(maxHeight);
		},
		[setTitle, setContent, setActions, setOpen],
	);

	const value = useMemo(
		() => ({
			open,
			title,
			content,
			actions,
			color,
			hideDialog,
			showDialog,
			maxWidth,
			maxHeight,
		}),
		[open, title, content, actions, color, maxHeight, hideDialog, showDialog, maxWidth],
	);

	return (
		<DialogServiceContext.Provider value={value}>
			{children}

			<Dialog
				onClose={hideDialog}
				open={open}
				PaperProps={{
					sx: {
						backgroundColor: 'background.offBlack',
						backgroundImage: 'none',
						maxWidth,
						width: '100%',
						margin: {
							xs: 0,
							lg: 4,
						},
						// height: '100%',
						borderRadius: {
							xs: 0,
							lg: 1,
						},
						maxHeight: maxHeight ?? {
							xs: '100%',
							lg: '90%',
						},
					},
				}}
			>
				{title && <DialogTitle sx={{ pb: 0 }}>{title}</DialogTitle>}

				<Typography color={color} component={Stack}>
					<DialogContent>{content}</DialogContent>

					<DialogActions>{actions}</DialogActions>
				</Typography>
			</Dialog>
		</DialogServiceContext.Provider>
	);
}
