'use client';

import { addViewCount, genaiFirebase, updateProjectData } from '@mpx-sdk/helpers/assets';
import { sanitizeString } from '@mpx-sdk/helpers/input';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { adminFeaturesAtom, savEditMode, singleAssetViewAtom, userAtom, userRolesAtom } from '@mpx-sdk/shared/atoms';
import { type PublicAsset } from '@mpx-sdk/types';
import Comments from '@mpx-sdk/ui/components/single-asset-view/components/Comments';
import Description from '@mpx-sdk/ui/components/single-asset-view/components/Description';
import SAVHeader from '@mpx-sdk/ui/components/single-asset-view/components/Header';
import Information from '@mpx-sdk/ui/components/single-asset-view/components/Information';
import Interactions from '@mpx-sdk/ui/components/single-asset-view/components/Interactions';
import Metrics from '@mpx-sdk/ui/components/single-asset-view/components/Metrics';
import Tags from '@mpx-sdk/ui/components/single-asset-view/components/Tags';
import Thumbnail from '@mpx-sdk/ui/components/single-asset-view/components/Thumbnail';
import User from '@mpx-sdk/ui/components/single-asset-view/components/User';
import { Button, Grid, Stack, Tooltip } from '@mui/material';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { useAtom, useAtomValue } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { isEmpty, isNumber } from 'lodash';
import { type ReactElement, useEffect } from 'react';
import { toast } from 'react-toastify';

interface ContentProps {
	/** If this is a dialog or not [default false] to show dialogue specific controls */
	isDialog?: boolean;
	/** The asset to display */
	asset?: PublicAsset;
	/** Callback to close the dialog */
	onClose?: () => void;
}

/** A component that displays the content of a single asset view. */
export default function Content({ isDialog = false, asset, onClose }: Readonly<ContentProps>): ReactElement | null {
	useHydrateAtoms([[singleAssetViewAtom, asset]]);

	const [editMode, setEditMode] = useAtom(savEditMode);
	const [projectData, setProjectData] = useAtom(singleAssetViewAtom);
	const adminView = useAtomValue(adminFeaturesAtom);
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);
	const projectPublic = isNumber(projectData?.id);
	const responsiveView = UIHelper.isResponsiveView();

	async function handleAssetEdit() {
		if (projectData?.type === 'history') {
			const title = sanitizeString(
				(
					document?.querySelector(
						`[data-modal-project-id="modal-project-${projectData?.id}"][data-modal-entry-type="title"] textarea`,
					) as HTMLInputElement
				)?.value ||
					projectData?.title ||
					'Untitled Project',
			)?.substring(0, 64);

			if (title && title !== projectData?.title) {
				updateDoc(doc(getFirestore(genaiFirebase), `ml-requests/${projectData?.originalData?.id}`), {
					projectTitle: title,
				});
			}

			setProjectData({
				...projectData,
				title,
			});

			setEditMode(false);
		} else {
			if (projectData) {
				const newProjectData: PublicAsset | null = await updateProjectData(projectData);

				if (newProjectData) {
					setProjectData(newProjectData);

					toast.success('Project updated successfully! May require a page refresh to see changes.');
				}
			}

			setEditMode(false);
		}
	}

	useEffect(() => {
		if (!currentUser && projectPublic && projectData?.id) {
			// add view count when user is not logged in
			addViewCount(projectData.id);
		} else if (currentUser && projectPublic && projectData?.id && currentUser?.id !== projectData?.user?.id) {
			// add view count when user is logged in and not the owner
			addViewCount(projectData.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isEmpty(projectData)) {
		return null;
	}

	return (
		<Grid
			alignItems='flex-start'
			className='single-asset-view'
			container
			data-testid='single-asset-view-content'
			direction='row'
			justifyContent='space-between'
			spacing={1}
			sx={
				isDialog
					? {
							padding: !responsiveView ? '1% 3%' : '1%',
							'.asset-model-viewer': {
								borderRadius: '20px',
							},
							width: '100%',
							maxWidth: 'min(94vw, 966px)',
						}
					: {}
			}
		>
			{isDialog && onClose && <SAVHeader onClose={onClose} />}

			{/* Asset thumbnail */}
			<Grid
				className='sav-thumbnail-container'
				item
				sx={{
					marginTop: isDialog ? '1.75rem' : 'initial',
				}}
				xs={12}
			>
				<Thumbnail />
			</Grid>

			{/* Asset description and tags */}
			<Grid container item spacing={2} sx={{ margin: 'auto 2%' }} xs={12}>
				{!responsiveView && (
					<Grid alignItems='center' container direction='row' item justifyContent='space-between'>
						<Grid item md={7} sm={12}>
							{projectPublic && <Metrics />}
						</Grid>

						<Grid
							item
							md={5}
							sm={12}
							sx={{
								display: 'flex',
								width: '100%',
							}}
						>
							<Interactions />
						</Grid>
					</Grid>
				)}

				{responsiveView && (
					<Grid
						item
						sm={12}
						sx={{
							display: 'flex',
							width: '100%',
						}}
					>
						<Stack
							alignItems='center'
							direction='row'
							justifyContent='space-between'
							spacing={2}
							sx={{
								display: 'flex',
								width: '100%',
							}}
						>
							{projectPublic ? <Metrics /> : <User />}

							<Interactions leftAligned={false} />
						</Stack>
					</Grid>
				)}

				<Grid item md={7} sm={12}>
					{(projectPublic || !responsiveView) && <User />}

					<Description />
				</Grid>

				<Grid item md={5} sm={12}>
					{projectPublic &&
						(!isEmpty(projectData.tags) ||
							currentUser?.id === projectData?.user?.id ||
							currentUser?.id === projectData?.owner?.id ||
							(currentUserRoles?.library && adminView)) && <Tags />}

					<Information />
				</Grid>
			</Grid>

			{(projectPublic || projectData?.type === 'history') && editMode && (
				<Stack
					alignContent='center'
					direction='row'
					justifyContent='center'
					spacing={4}
					sx={{
						width: '100%',
					}}
				>
					<Tooltip
						arrow
						describeChild
						title={`Stop editing "${projectData?.title ?? 'Untitled Project'}" and discard changes`}
					>
						<Button
							aria-label='Cancel and discard changes'
							color='error'
							onClick={() => setEditMode(false)}
						>
							Discard Changes
						</Button>
					</Tooltip>

					<Tooltip
						arrow
						describeChild
						title={`Save changes and update "${projectData?.title ?? 'Untitled Project'}"`}
					>
						<Button
							aria-label='Save changes'
							className={`sav-header-open-button ${
								currentUserRoles?.library && adminView && currentUser?.id !== projectData?.user?.id
									? 'admin-button'
									: ''
							}`}
							color='primary'
							fullWidth
							onClick={() => handleAssetEdit()}
							sx={{
								m: 1,
								margin: '2em auto auto auto',
								maxWidth: '280px',
								backgroundColor: 'primary.main',
								'&:hover': {
									backgroundColor: 'primary.light',
								},
							}}
							variant='contained'
						>
							Save Changes
						</Button>
					</Tooltip>
				</Stack>
			)}

			{/* Fast Comments */}
			{projectPublic && (
				<Grid item sx={{ margin: 'auto 2%' }} xs={12}>
					<Comments />
				</Grid>
			)}
		</Grid>
	);
}
