/* eslint-disable no-nested-ternary */
import { validators } from '@mpx-sdk/helpers/validators';
import { type FormField } from '@mpx-sdk/shared/configs';
import UploadButton from '@mpx-sdk/ui/components/core/UploadButton';
import {
	Button,
	Chip,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
	TextField,
} from '@mui/material';
import { isString } from 'lodash';
import { Field, Form } from 'react-final-form';

interface IFields {
	[key: string]: FormField;
}

export default function ReusableForm({
	fields,
	onSubmit,
	id,
	noSubmitButton,
	submitLabel = 'Submit',
}: {
	fields: IFields;
	onSubmit: (values: any) => void;
	id?: string;
	spacing?: number;
	submitLabel?: string;
	noSubmitButton?: boolean;
}) {
	const initialValues = Object.entries(fields).reduce((acc, [key, field]) => {
		if (field.type === 'radio' || field.type === 'select') {
			acc[key] = field.options.find(({ default: isDefault }) => isDefault)?.value;
		} else if (field.type === 'upload') {
			acc[key] = [];
		} else if (field.default) acc[key] = field.default;

		return acc;
	}, {});

	return (
		<Form id={id} initialValues={initialValues} keepDirtyOnReinitialize onSubmit={onSubmit}>
			{({ handleSubmit, values, form }) => (
				<form id={id} onSubmit={handleSubmit}>
					<Grid container spacing={2}>
						{Object.entries(fields).map(([name, field]) => {
							const options = (field.options || []).filter((option) => {
								const { dependsOn } = option;

								return (
									!dependsOn ||
									Object.entries(dependsOn).every(([key, value]) => values[key] === value)
								);
							});

							if (field?.dependsOn) {
								const dependsOn = Object.keys(field?.dependsOn).map((dep) => {
									if (
										isString(field.dependsOn)
											? values[dep] !== field.dependsOn[dep]
											: !field.dependsOn[dep].includes(values[dep])
									) {
										return false;
									}

									return true;
								});

								if (dependsOn.includes(false)) {
									return null;
								}
							}

							const InputComponent = {
								radio: RadioGroup,
								select: Select,
								text: TextField,
								label: '',
								button: '',
								upload: '',
								number: TextField,
							}[field.type];

							return (
								<Grid item {...field.grid} key={name}>
									{field.type === 'label' ? (
										<FormLabel>{field.label}</FormLabel>
									) : field.type === 'button' ? (
										<Button
											color={field.color}
											fullWidth
											onClick={(e) => field?.onClick?.(e, form)}
											variant={field.variant}
										>
											{field.label}
										</Button>
									) : field.type === 'upload' ? (
										<Field name={name} type='file'>
											{({ input }) => (
												<UploadButton fullWidth inputProps={input}>
													{field.label}
												</UploadButton>
											)}
										</Field>
									) : (
										<Field name={name} validate={validators.required}>
											{({ input, meta }) => (
												<FormControl fullWidth>
													{['radio'].includes(field.type) && (
														<FormLabel>{field.label}</FormLabel>
													)}
													{field.type === 'select' && <InputLabel>{field.label}</InputLabel>}
													<InputComponent
														{...input}
														error={meta.error && meta.touched}
														label={field.label}
														multiline={
															field.type === 'text' && field.multiline
																? 'true'
																: undefined
														}
														multiple={
															['upload', 'select'].includes(field.type) && field.multiple
														}
														row={
															field.type === 'radio'
																? field?.direction === 'row'
																: undefined
														}
														rows={field.type === 'text' ? field.rows : undefined}
														{...field.inputProps}
													>
														{options.map((option: any) =>
															field.type === 'radio' ? (
																<FormControlLabel
																	key={option.value}
																	control={<Radio />}
																	label={option.label}
																	value={option.value}
																/>
															) : (
																<MenuItem key={option.value} value={option.value}>
																	{option.label}
																</MenuItem>
															),
														)}
													</InputComponent>
													<FormHelperText>{meta.touched && meta.error}</FormHelperText>
													{field?.suggestions && (
														<Stack
															columnGap={1}
															direction='row'
															flexWrap='wrap'
															mt={0.5}
															rowGap={1}
														>
															{field.suggestions?.map((suggestion: any) => (
																<Chip
																	key={suggestion.value}
																	label={suggestion.label}
																	onClick={() => form.change(name, suggestion.value)}
																/>
															))}
														</Stack>
													)}
													{field.helperText && (
														<FormHelperText>{field.helperText}</FormHelperText>
													)}
												</FormControl>
											)}
										</Field>
									)}
								</Grid>
							);
						})}
						{!noSubmitButton && (
							<Grid item xs={12}>
								<Button fullWidth type='submit' variant='contained'>
									{submitLabel}
								</Button>
							</Grid>
						)}
					</Grid>
				</form>
			)}
		</Form>
	);
}
